import * as Yup from "yup";

export const MarriageSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  email: Yup.string()
    .email("Email inválido")
    .required("Campo requerido"),
  phone: Yup.string()
    .matches(/(\+57)[0-9]{8}|(9)[0-9]{8}/g, {
      message: "Teléfono inválido",
    })
    .required("Campo requerido"),
  date: Yup.date()
    .required("Fecha requierida")
    .nullable(),
  place: Yup.string().required("Campo requerido"),
  message: Yup.string().required("Campo requerido"),
});

export const AddressTabSchema = Yup.object().shape({
  tickets: Yup.array()
    .of(
      Yup.object().shape({
        addresses: Yup.array().of(
          Yup.object().shape({
            address: Yup.string().required("address_is_required"),
            commune_id: Yup.string().required("countie_is_required"),
          })
        ),
      })
    )
    .min(1, "Se debe seleccionar al menos 1 ticket"),
});

export const PassengerSchema = Yup.object().shape({
  tickets: Yup.array()
    .of(
      Yup.object().shape({
        passengers: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required("name_is_required"),
            email: Yup.string()
              .email("email_is_invalid")
              .required("email_is_required"),
            phone: Yup.string()
              .min(8, {
                message: "phone_is_invalid",
              })
              .required("phone_is_required"),
            passengerAddress: Yup.string().required("address_is_required"),
          })
        ),
      })
    )
    .min(1, "Se debe seleccionar al menos 1 ticket"),
});

export const TicketsSchema = Yup.object().shape({
  tickets: Yup.array()
    .of(Yup.object().shape({}))
    .min(1, "MINIMO 1"),
});

export const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  email: Yup.string()
    .email("Email inválido")
    .required("Campo requerido"),
  phone: Yup.string()
    .matches(/(\+57)[0-9]{8}|(9)[0-9]{8}/g, {
      message: "Teléfono inválido",
    })
    .required("Campo requerido"),
  message: Yup.string().required("Campo requerido"),
});

export const defaultSchema = Yup.object().shape({});

export const ResetPasswordSchema = Yup.object().shape({
  password1: Yup.string().required("Campo requerido"),
  password2: Yup.string().required("Campo requerido"),
});

export const createPrivateEventFormSchema = Yup.object().shape({
  eventName: Yup.string().required("Campo Requerido"),
  placeName: Yup.string().required("Campo Requerido"),
  placeAddress: Yup.string().required("Campo Requerido"),
  countyId: Yup.string().required("Campo Requerido"),
  startDate: Yup.date().required("Fecha de inicio Requerida"),
  startHour: Yup.string().required("Hora de inicio Requerido"),
  endHour: Yup.string().required("Hora de término Requerido"),
  invitationMessage: Yup.string().required("Campo Requerido"),
});

export const frequentFriendSchema = Yup.object().shape({
  fullname: Yup.string().required("Campo Requerido"),
  phone: Yup.string()
    .matches(/(\+569)[0-9]{8}|(9)[0-9]{8}/g, {
      message: "Teléfono inválido",
    })
    .required("Campo requerido"),
  email: Yup.string()
    .email("Email inválido")
    .required("Campo requerido"),
});

export const frequentAddressesSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Debe ser mayor  3 caracteres")
    .required("Campo Requerido"),
});

export const CouponSchema = Yup.object().shape({
  discount: Yup.string().required("coupon_is_required"),
});
