import { response } from './client'

export const getRecommendedEvents = () => {
  const options = {
    url: '/recommended-events',
  }
  return response(options)
}

export const getCounties = () => {
  const options = {
    url: '/counties?country-code=CO',
  }
  return response(options)
}

export const getEventsByType = (countryCode, type) => {
  const options = {
    url: `/events?type=${type}&country-code=${countryCode}`,
  }
  return response(options)
}

export const getBannerSlides = (countryCode) => {
  const options = {
    url: `/landing-slides?country-code=${countryCode}`,
  }
  return response(options)
}

export const getComments = (countryCode) => {
  const options = {
    url: `/landing-comments?country-code=${countryCode}`,
  }
  return response(options)
}

export const getChannelsPage = (countryCode) => {
  const options = {
    //url: `/channels/vmove.agua.devaid.cl`, //ACTUALIZAR EN PRODUCCION
    url: `/channels/${process.env.REACT_APP_API_URL_CLEAN}`, //ACTUALIZAR EN PRODUCCION
  }
  return response(options)
}

export const getCountriesPage = () => {
  const options = {
    url: `/countries`,
  }
  return response(options)
}

export const getLocalesPage = (countryCode) => {
  const options = {
    url: `/locales?country-code=${countryCode}`,
  }
  return response(options)
}

export const getPaymentMethods = (countryCode) => {
  const options = {
    url: `/payment-methods?&country-code=${countryCode}`,
  }
  return response(options)
}

export const getEventBySlug = (slug) => {
  const options = {
    url: `/events/${slug}`,
  }
  return response(options)
}

export const getProvinceByEvent = (eventId) => {
  const options = {
    url: `/provinces/by-event/${eventId}`,
  }
  return response(options)
}

export const getGroupsByEvent = (eventId) => {
  const options = {
    url: `/events/${eventId}/product-variant`,
  }
  return response(options)
}

export const setSearch = (param) => {
  const options = {
    url: `/search`,
    params: { q: param },
  }
  return response(options)
}

export const postContact = (countryCode, data) => {
  const options = {
    method: 'POST',
    url: `/contact`,
    params: { 'country-code': countryCode },
    data,
  }
  return response(options)
}

export const postMarriage = (countryCode, data) => {
  const options = {
    method: 'POST',
    url: `/marriage`,
    params: { 'country-code': countryCode },
    data,
  }
  return response(options)
}

export const getOrderDetail = (id) => {
  const options = {
    url: `/order-detail/${id}`,
  }
  return response(options)
}

export const postCheckQr = (orderId) => {
  const options = {
    method: 'POST',
    url: `/order/${orderId}/check-complete`,
  }
  return response(options)
}
